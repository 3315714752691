var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    [
      _c("date-dialog", {
        attrs: {
          isDialogVisible: _vm.isDateDialogVisible,
          dateRange: _vm.dateRange,
        },
        on: {
          dialogAction: _vm.setDateRange,
          closeDialog: _vm.closeDateDialog,
        },
      }),
      _c(
        "el-container",
        { attrs: { direction: "vertical" } },
        [
          _c(
            "div",
            { staticClass: "menu-buttons" },
            [
              _c("el-button", { on: { click: _vm.openDateDialog } }, [
                _vm._v(
                  "Выбрать дату (" +
                    _vm._s(this.$data.dateRange[0]) +
                    " - " +
                    _vm._s(this.$data.dateRange[1]) +
                    ")"
                ),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "table-wrapper" },
            [
              _c("el-input", {
                staticStyle: { width: "50%" },
                attrs: { type: "textarea", autosize: true, resize: "none" },
                model: {
                  value: _vm.dataText,
                  callback: function ($$v) {
                    _vm.dataText = $$v
                  },
                  expression: "dataText",
                },
              }),
              _c("el-input", {
                staticStyle: { width: "50%" },
                attrs: { type: "textarea", autosize: true, resize: "none" },
                model: {
                  value: _vm.statusesText,
                  callback: function ($$v) {
                    _vm.statusesText = $$v
                  },
                  expression: "statusesText",
                },
              }),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "check-btn",
              on: {
                click: function ($event) {
                  return _vm.checkSales()
                },
              },
            },
            [_vm._v("Проверить")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }