





















import { Vue, Component } from "vue-property-decorator";
import moment from "moment";

import DateDialog from "@/components/dialogs/DateDialog.vue";

@Component({
    components: {
        "date-dialog": DateDialog,
    },
})
export default class Main extends Vue {
    private isDateDialogVisible: boolean = false;
    private dataText: string = "";
    private statusesText: string = "";

    private data(): object {
        return {
            dateRange: [moment().startOf("month").format("YYYY-MM-DD"), moment().endOf("month").format("YYYY-MM-DD")],
        };
    }

    private closeDateDialog() {
        this.isDateDialogVisible = false;
    }

    private openDateDialog() {
        this.isDateDialogVisible = true;
    }

    private setDateRange(dateRange: any[]) {
        this.$data.dateRange = dateRange;

        this.closeDateDialog();
    }

    private async checkSales() {
        try {
            const [startDate, endDate] = this.$data.dateRange;

            const res = await this.$Api.Main.CheckCall({
                json: true,
                data: this.dataText.split("\n"),
                startDate,
                endDate,
            });

            this.statusesText = res.map((r) => r.status).join("\n");
        } catch (error) {
            alert(error.message || error);
        }
    }
}
